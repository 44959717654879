# Choose the endpoint to submit CDIL forms to
export getCdilOrigin = -> switch
	when process.env.NETLIFY_FUNCTIONS_URL
		return process.env.NETLIFY_FUNCTIONS_URL
	when process.env.APP_ENV == 'dev'
		return 'https://deploy-dev--clif-bar-cde-api.netlify.app'
	when process.env.APP_ENV == 'uat'
		return 'https://deploy-uat--clif-bar-cde-api.netlify.app'
	else
		return 'https://clif-bar-cde-api.netlify.app'
