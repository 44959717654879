###
This mixin should be added to all pages/towers
###
import URL from 'url-parse'
import { makeNuxtUrl } from 'library/services/helpers'
import debounce from 'lodash/debounce'
import runsExperimentsMixin from './runs-experiments'
import getAdditionalSites from '~/queries/pages/additional-sites.gql'
{ getCurrentIsoCode } = require 'library/services/i18n/locales'
import { getOneTrust } from 'library/services/onetrust/onetrust-loading'
import { firePageView } from 'library/services/gtm/page-view'

export default

	mixins: [ runsExperimentsMixin ]

	# Default to auto generating the head
	head: -> @buildHead()

	data: -> hreflangs: [] # Expected to be set by asyncData

	mounted: ->

		# Fire GTM pageview event
		firePageView { @$gtm, @$store, @page }

		# Listen for content changes from Craft's preview mode
		window.addEventListener 'message', @onPostMessage

		# Immediately refresh if mounting into a preview request on SSG.
		if process.static && (@$route.query['x-craft-live-preview'] || @$route.query['x-craft-preview'])
		then @refreshData()

		# Alert PriceSpider of new page. PriceSpider is currently only present
		# on international sites.
		@$wait 100, window.PriceSpider?.rebind

		# Tell OneTrust to re-intialize.  This is done so that iframe and script
		# blocking OneTrust code (like the data-src stuff) will get noticed by
		# OneTrust. Not polling for OneTrust because it will unblock if it runs
		# after mounted
		# https://my.onetrust.com/s/feeddetail?feedId=0D53q0000Ag98VZCQY
		# https://my.onetrust.com/articles/en_US/Knowledge/UUID-69162cb7-c4a2-ac70-39a1-ca69c9340046
		getOneTrust().then (OneTrust) =>
			return unless OneTrust.IsAlertBoxClosed()
			document.getElementById('onetrust-consent-sdk')?.remove()
			OneTrust.InitializeBanner()
			# Wait a tick to workaround intermitent appendChild error when SSG
			@$wait 100, => OneTrust.LoadBanner()

	# Cleanup listeners
	destroyed: -> window.removeEventListener 'message', @onPostMessage

	methods:

		# Helper to make head tags. Passed in props are only used if explicit meta
		# values aren't found
		buildHead: ({ title, description, image } = {}) ->

			# If no page, like on error pages, abort
			return unless @page

			# If there is an explicit marqueeImage field, us it
			if !image and @page.marqueeImage
				image = @page.marqueeImage[0]?.desktop[0]?.w1440

			# If no image, check if the page has blocks and get the image from
			# there (expecting it to be a standard marquee)
			if !image and @page.blocks?.length
				if marquee = @page.blocks.find ({ __typename }) ->
					__typename == 'blocks_standardMarquee_BlockType'
				then image = marquee.image[0]?.desktop[0]?.w1440

			# Title, no fallback since there's always an assumed title
			_title = @page.metaTitle or @page.deeplinkTitle or title or @page.title

			# Description: Meta first, then find description, else global fallback
			_description = @page.metaDescription or @page.answer or description

			_image = switch

				# Meta image takes precidence
				when @page.metaImage?.length then @page.metaImage

				# If no meta image, use the listing image
				when @page.listingImage?.length then @page.listingImage

				# should be either a marquee image or the standard marquee image
				when image?.length then image

			# Auto create canonical URL
			canonical =
				hid: 'canonical'
				rel: 'canonical'
				href: process.env.NUXT_APP_URL + @$route.path

			# Create the object, filtering empties
			title: _title
			meta: @$nonEmpty [
				@$metaTag 'og:title', _title
				@$metaTag 'description', _description
				@$metaTag 'og:image', _image
				@$metaTag 'robots', @page.robots?.join ', '
			]
			script: @$nonEmpty [ @experimentsSdkScript ]
			link: [
				canonical
				...@hreflangs
			]

		# Handle postMessages, looking for preview content updates
		onPostMessage: ({ origin, data }) ->
			return unless origin == (new URL process.env.CMS_ENDPOINT).origin
			if data?.message == 'preview:change'
			then @refreshData data.refreshType

		# Refetch the page content then replace the page data. Clear the
		# $payloadURL so we don't re-use cached JSON on production.
		refreshData: debounce (refreshType) ->

			# Update global data, like the navigation
			if refreshType == 'global'
			then @$store.dispatch 'loading/while', =>
				await @$store.dispatch 'globals/fetch'

			# Reset the data for the page
			else @$store.dispatch 'loading/while', =>
				await @$nuxt.refresh()

		, 50

# Make hreflang head objects
export buildHreflangs = ({ $craft, entryId }) ->
	currentIsoCode = getCurrentIsoCode()

	# Get all site-entry relations for this entry
	perSiteEntries = await $craft.getEntries
		query: getAdditionalSites
		variables:
			id: entryId

	# Filter out any that are part of the current site
	hreflangLinks = (perSiteEntries || []).filter (entry) =>
		entry.language != currentIsoCode

	# Filter out dev and uat links
	.filter (entry) ->
		!entry.url.includes('dev-www') &&
		!entry.url.includes('uat-www')

	# Make vue head style entries
	.map (entry) ->
		rel: 'alternate'
		hreflang: entry.language
		href: entry.url

	# If this is the US site, add x-default
	if currentIsoCode == 'en-US'
		if currentEntry = perSiteEntries.find (entry) ->
			entry.language == currentIsoCode
		then hreflangLinks.push
			rel: 'alternate'
			hreflang: 'x-default'
			href: currentEntry.url

	# Return link objects
	return hreflangLinks
