import { GTM_EVENTS } from 'library/constants'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'

# Subscribe to VueX mutations that update customer info
export fireEventOnCustomerMutation = (store) ->
	store.subscribe (mutation, state) ->
		return unless mutation.type == 'customer/setCustomer'
		fireIdentifyCustomerEvent state.customer

# Service to instantly push customer email and id to dataLayer
fireIdentifyCustomerEvent = (customer) ->

	# Flatten customer data into expected payload
	payload =
		customerId: customer.id
		customerEmail: customer.email
		customerFirstName: customer.firstName
		customerLastName: customer.lastName
		customerAddress: [
			customer.defaultAddress?.address1
			customer.defaultAddress?.address2
		].filter((val) -> !!val).join(' ')
		customerCity: customer.defaultAddress?.city
		customerState: customer.defaultAddress?.state
		customerZip: customer.defaultAddress?.zip
		customerCountry: customer.defaultAddress?.country
		customerPhone: customer.defaultAddress?.phone
		mdlzId: customer.mdlzId

	# Remove empty keys
	# https://stackoverflow.com/a/33432857/59160
	payload = pickBy payload, identity

	# Fire the dataLayer event
	dataLayer.push {
		event: GTM_EVENTS.IDENTIFY_CUSTOMER,
		...payload
	}
