import { GTM_EVENTS } from 'library/constants'

# Fire a page view event once the cart is loaded
export firePageView = ({ $gtm, $store, page }) ->

	# Fire the page view
	firePageView = -> $gtm.push
		event: GTM_EVENTS.PAGE_MOUNTED
		objectId: page?.id || ''
		objectType: page?.__typename || ''
		totalPrice: $store.getters['cart/total'].toFixed(2)

	# Wait for cart to be hydrated for totalPrice
	$store.watch(
		(state) -> state.cart.hydrated
		(hydrated) -> firePageView() if hydrated
		immediate: true
	)

