//
//
//
//
//
//

import { loadScript } from 'library/services/helpers'

export default {
	props: {
		recaptchaSiteKey: {
			type: String,
			required: true
		},
		isEnterprise: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			widgetId: null,
		}
	},
	computed: {
		sdkUrl() {
			return this.isEnterprise
				? 'https://www.google.com/recaptcha/enterprise.js?render=explicit'
				: 'https://www.google.com/recaptcha/api.js?render=explicit'
		}
	},
	mounted() {
		this.loadRecaptcha(this.sdkUrl)
			.then(this.waitForWindowApi)
			.then((grecaptcha) => {
				grecaptcha.ready(() => {
					if (!this.$refs.grecaptchaRef) return
					if (this.$refs.grecaptchaRef.querySelector('iframe')) return
					this.widgetId = grecaptcha.render(this.$refs.grecaptchaRef, {
						sitekey: this.recaptchaSiteKey,
						action: 'submit'
					})
				})
			})
	},
	methods: {
		loadRecaptcha(sdkUrl) {
			if (window.grecaptcha) return Promise.resolve()
			return loadScript(sdkUrl)
		},
		waitForWindowApi() {
			return new Promise((resolve) => {
				const checkLoad = () => {
					const grecaptcha = this.getRecaptchaApi()
					if (grecaptcha) resolve(grecaptcha)
					else setTimeout(() => checkLoad(), 50)
				}
				checkLoad()
			})
		},
		getRecaptchaApi() {
			return this.isEnterprise ?
				window.grecaptcha?.enterprise :
				window.grecaptcha
		},
		getToken() {
			if (this.widgetId == null) return console.debug('Missing widgetId')
			const grecaptcha = this.getRecaptchaApi()
			if (!grecaptcha) return console.debug('Missing recaptcha API')
			return grecaptcha.getResponse(this.widgetId)
		}
	}
}
